<template>
  <h1 v-if="error !== null" class="text-center">
    Invalid Credentials... redirecting to dashboard in {{ counter }} seconds
  </h1>
  <h1 v-else class="text-center">{{ statusText }}</h1>
</template>

<script>
export default {
  name: 'UserActivation',
  async created() {
    console.log(this.$route.query)
    if (this.uidb64 && this.token) {
      await this.activateUser()
    } else {
      // Show No Params Message
    }
  },
  computed: {
    uidb64: function() {
      return this.$route.query && this.$route.query.uidb64
        ? this.$route.query.uidb64
        : null
    },
    token: function() {
      return this.$route.query && this.$route.query.token
        ? this.$route.query.token
        : null
    },
    statusText: function() {
      return this.activated ? 'Redirecting...' : 'Processing Activation...'
    },
  },
  data() {
    return {
      activated: false,
      error: null,
      counter: 5,
    }
  },
  methods: {
    triggerDelayedRedirection: function() {
      setInterval(() => {
        if (this.counter === 1) {
          this.$router.replace('/')
        } else {
          this.counter -= 1
        }
      }, 1000)
    },
    activateUser: async function() {
      try {
        this.error = null
        const payload = {
          uidb64: this.uidb64,
          token: this.token,
        }
        await this.$store.dispatch('auth/activate', payload)
        this.activated = true
        this.$router.push('/update-password')
      } catch (error) {
        this.error = error
        console.log('Invalid credentials')
        this.triggerDelayedRedirection()
      }
    },
  },
}
</script>
